import React from 'react';
import {
  Redirect,
} from "react-router-dom";
import LinkButton from '../Utility/LinkButton';
import RankItemEditCondensed from "../RankItem/RankItemEditCondensed";
import { getMyListById, saveList } from '../API/RankList';
import RankViewRouting from "../Utility/RankViewRouting";
import { gotToEditList } from "../Utility/RouteBuilder";
import { UserState } from '../User/UserState';
import { uuidv4 } from '../Utility/uuidv4';

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.delaySave = this.delaySave.bind(this);
    this.mySaveList = this.mySaveList.bind(this);
    this.rankItemChanged = this.rankItemChanged.bind(this);
    this.clearEditIndex = this.clearEditIndex.bind(this);
    this.discoverListHolder = {};
    this.state = {
      item: {
        id: uuidv4(),
        name: ""
      },
      typingTimeout: 0,
      redirect: false,
      url: props.redirect,
    }
  }
  
  clearEditIndex() {
    var url = gotToEditList(this.discoverListHolder.id);
    console.log(this.discoverListHolder);
    this.setState({
      "redirect": true, "url": url
    })
  }

  rankItemChanged(oldItem, newItem) {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    var foundItem = this.discoverListHolder.rankItems.find(x => x.id == newItem.id);
    var index = this.discoverListHolder.rankItems.indexOf(foundItem);
    this.discoverListHolder.rankItems[index] = newItem;
    console.log(index,oldItem,newItem);
    foundItem = newItem;
    this.setState({
      item: newItem
    });

    this.typingTimeout = this.delaySave();
  }

  delaySave() {
    var self = this;
    return setTimeout(function () {
        self.mySaveList.call(self, self.discoverListHolder);
      }, 2000)
  }

  mySaveList(data) {
      return saveList(this.discoverListHolder, this.discoverListHolder.id)
          .then(console.log);
  }
  
  componentDidMount() {
    var rankListId = UserState().discovery_list;
    getMyListById(rankListId)
      .then(res => {
        this.discoverListHolder = res.list_body;
        this.discoverListHolder.id = res.id;
        this.discoverListHolder.rankDetails = {"name": "Discover List"};
        if(!this.discoverListHolder.rankItems) {
          this.discoverListHolder.rankItems = [];
        }
        this.discoverListHolder.rankItems.push(this.state.item);
      })
  }

  render() {
    return (
        <div className="rankList">
          <RankViewRouting 
            url={this.state.url}
            redirect={this.state.redirect}
          />
          <div className="read_view">
          <div className="aRankItem">
            <div className="rankItem insta">
              <RankItemEditCondensed 
                item={this.state.item}
                rankItemChanged={this.rankItemChanged}
                clearEditIndex={this.clearEditIndex.bind(this)}
              />
              </div>
          </div>
          </div>
        </div>
    )
  }
}

export default Header;