import React from 'react';
import ReactDOM from 'react-dom';
import EditRankListDetails from '../RankList/EditRankListDetails';
import EditRankItems from '../RankList/EditRankItems';
import AddRankItemButton from '../RankList/AddRankItemButton';
import { useParams } from "react-router-dom";
import update from 'immutability-helper';
import RankViewAPI from "../RankViewApi";
import RankListActions from "../RankList/RankListActions";
import RankViewRouting from "../Utility/RankViewRouting";
import RankItemRender from "../RankItem/RankItemRender";
import VisitorActions from "../Utility/VisitorActions";
import { getStackSquash } from '../API/RankList';
import VotedRankListNotice from '../RankList/VotedRankListNotice';

class VotedRank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataLoading: true,
      rankListId: props.rankId,
      rankDetails: {
        name: ''
      },
      rankItems: [
      ],
      rawData: {},
      parentRankId: ""
    }
  }

  componentDidMount() {
    getStackSquash(this.state.rankListId)
      .then(this.dataTostate.bind(this));
  }

  dataTostate(data) {
    data.list_body.rankItems.sort((a,b) => (a.rank > b.rank) ? 1: -1);
    this.setState(data.list_body, () => {
      this.state.dataLoading = false;
    });
    var parentRankId = this.props.rankId;
    data.id = parentRankId;
    data.list_body.rankListId = parentRankId;
    console.log(data);
    this.setState({rawData: data});
    this.setState({parentRankId: parentRankId});
    console.log(parentRankId);
    //This rankView have stackSquash?
  }
  
  render() {
    const items = this.state.rankItems.map((item, key) =>
          <RankItemRender 
            editable={false}
              item={item}
              key={item.id}
          />
      );

    return (
      <div className="rankList">
        <h1> 
          {this.state.rankDetails.name} 
        </h1>
        
        <div className="read_view">
          <div>{items}</div>
        </div>

        <VisitorActions data={this.state.rawData} rankViewId={this.state.rankListId}  />
      </div>
    )
  }
}

export default VotedRank;