import React from 'react';
import {
  useHistory,
  Router,
  Link
} from "react-router-dom";
import { slide as Menu } from 'react-burger-menu'
import logo from '../MyRankLogo.png'
import Feedback from "../Utility/Feedback";
import "./Header.css";
import {goToDiscoverItem, goToFavorites, goToSignUp, goToLogin, goToAccountSettings, goToUserHome} from "../Utility/RouteBuilder";
import LinkButton from "../Utility/LinkButton";

function HomeImage() {
  let history = useHistory();

  function handleClick() {
    history.push("/");
  }

  return (
    <img className="logo" onClick={handleClick} src={logo} alt="Logo" />
  );
}

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.logOff = this.logOff.bind(this);
  }

  logOff() {
    localStorage.removeItem("User");
    window.location.href = "/";
  }

  render() {
    return (
        <div className="top-bar">
          <div className="siteIcon logoHeader">
             <HomeImage />
             <span className="beta-badge">BETA</span>
          </div>

          <Feedback />
          
          { !this.props.userData.username && 
            <div className="accountActions">
              <LinkButton 
                btnTitle="Login"
                url={goToLogin()}
                btnClasses="btn btn-primary loginBtn"
              />
              <LinkButton 
                btnTitle="Sign Up"
                url={goToSignUp()}
                btnClasses="btn btn-primary signUpButton"
              />
            </div> 
          }
          {this.props.userData.username && 
             <LinkButton 
                btnTitle="+"
                url={goToDiscoverItem()}
                btnClasses="btn btn-success discoverItemButton"
              /> 
          }
          {this.props.userData.username && 
            <Menu right>
              <LinkButton 
                btnTitle="Account Settings"
                url={goToAccountSettings()}
                btnClasses="btn btn-link btn-lg"
              />
              <LinkButton 
                btnTitle="My Favorites"
                url={goToFavorites()}
                btnClasses="btn btn-link btn-lg"
              />
              <LinkButton 
                btnTitle="My Lists"
                url={goToUserHome(this.props.userData.username)}
                btnClasses="btn btn-link btn-lg"
              />
              <button className="btn btn-link btn-lg" onClick={this.logOff}>
                Log Off
              </button>
            </Menu>
          }
          
        </div>
    )
  }
}

export default Header;
