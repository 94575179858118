import React from 'react';

class RankItemPricing extends React.Component {

  constructor(props) {
    super(props);
  }

  addPriceDivs = () => {
    let priceDivs = [];
    for(var i=0; i < this.props.item.pricing; i++) {
      priceDivs.push(<span key={i}>$</span>);
    }
    return priceDivs;
  }

  render() {
    return (
        <div>
          {this.addPriceDivs()}
        </div>
    )
  }
}

export default RankItemPricing;