import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {Typeahead, AsyncTypeahead} from 'react-bootstrap-typeahead';


//This is using the model....
class AddRankItemButton extends React.Component {
  constructor(props) {

    super(props);
    // this.addRankItem = this.addRankItem.bind(this);
    this.state = {
      showModal: false,
      rankItemName: 'yar',
      //selected: ["a"],
      //options: ["a", "b"],
      //isLoading: false,
      
      allowNew: false,
      isLoading: false,
      multiple: false,
      options: [],
    }
  }

  _handleChange(e) {
    const { checked, name } = e.target;
    this.setState({ [name]: checked });
  }

  _handleSearch(query) {
    this.setState({ isLoading: true });
    fetch("http://localhost:3001/search/" + query)
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          isLoading: false,
          options: data,
        });
      });
  }

  addRankItem() {
    this.props.addRankItem();
  }
  
  closeModal() {
    this.setState({showModal: false})
  }

  showModal() {
    this.setState({showModal: true});
  }

  addRankItem() {
    console.log("Add th item?");
    this.props.addRankItem({
      name: 'Click To Edit'
    });
    this.closeModal();
  }

  searchForItem({selected}) {
    console.log(selected);
    this.setState({selected});
  }

  render() {
    return (
      <div>
        <button 
          className="button"
          onClick={this.addRankItem.bind(this)}
          type="button" >
          Add New Item
        </button>
      </div>
    );
  }
};

export default AddRankItemButton;
