import React from 'react'
import Modal from 'react-bootstrap/Modal';
import RankViewAPI from "../RankViewApi";

class PublishRankList extends React.Component {

  constructor(props) {
    super(props);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.publishList = this.publishList.bind(this);
    this.state = {
    	publishOption: "public",
    	showPublishOptions: true,
    	isProcessing: false
    }
  }

  publishList() {
  	const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
      {
      	list_id: this.props.rankListId,
      	unlisted: this.state.publishOption == "unlisted" ? 1 : 0
      })
    };
    this.setState({isProcessing: true});

  	return RankViewAPI("publish", requestOptions, true)
  		.then(() => {
  			this.setState({showPublishOptions: false});
  		});
  }

  handleOptionChange(e) {
  	
  	this.setState({
  		publishOption: e.target.value
  	})
  }

  render() {
    return (
		<div className="container">
			<div className="card col-sm-8">
				<h1 className="d-flex justify-content-center"> 
	            	Sharing
	          	</h1>
	          	<div className="card-body">
					{!this.state.showPublishOptions && 
						<div>
							<div>Your share link</div>
							<a href={ window.location.origin + "/view/" + this.props.rankListId}>
							{window.location.origin}/view/{this.props.rankListId}</a>
						</div>
					}

					{this.state.showPublishOptions && 
					<div>
						<div className="row p-3">
							<div>
								<input
									value="unlisted" 
									checked={this.state.publishOption == "unlisted"} 
									name="publishStatus" 
									type="radio"
									onChange={this.handleOptionChange.bind(this)}
								/>
							 	Unlisted - this will allow you to share you list via a link but not be searchable.
							 </div>
						</div>

						<div className="row p-3">
							<div>
								<input
									value="public" 
									checked={this.state.publishOption == "public"} 
									name="publishStatus" 
									type="radio"
									onChange={this.handleOptionChange.bind(this)}
								/>
									Public - This will allow you to share your list via a link and also show up in search.
							 </div>
						</div>

						 {!this.state.isProcessing &&
		                  <div 
							className="btn btn-success d-flex justify-content-center p-3"
							onClick={this.publishList.bind(this)}
							>Publish</div>
		                }
		                {this.state.isProcessing && 
		                  <div className="alert alert-success">Request Sent</div>
		                }
						
					</div>}
				</div>
			</div>
       </div>
    )
  }
}


export default PublishRankList;