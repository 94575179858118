import React from "react";
import ImageUploader from "react-images-upload";
import Modal from 'react-bootstrap/Modal';
import RankViewAPI from "../RankViewApi";
import RankViewRouting from "./RankViewRouting";

class ImageUploaderBah extends React.Component {
  constructor(props) {
    super(props);
    this.onHide = () => { }
    this.login = this.login.bind(this);
    this.signUp = this.signUp.bind(this);
    this.state ={
      url: '',
      redirect: '',
    }
  }
  
  login() {
    this.setState({
      url: this.props.loginUrl,
      redirect: true
    })
  }

  signUp() {
    this.setState({
      url: this.props.signUpUrl,
      redirect: true
    })
  }

  render() {
    return (
      <div>
        <RankViewRouting 
              url={this.state.url}
              redirect={this.state.redirect}
            />
          <Modal show={this.props.show} onHide={this.onHide} >
            <Modal.Header closeButton onClick={this.props.closeModal}>
              <Modal.Title>Account Required</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
              {this.props.message}            
            </Modal.Body>
          
          <Modal.Footer>  
            <div 
              className="btn btn-success"
              onClick={this.login.bind(this)}> Login </div> 
            <div 
              className="btn btn-success"
            onClick={this.signUp.bind(this)}> Sign up </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}


export default ImageUploaderBah;