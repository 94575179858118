import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class RankItemLinks extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div className="rankItemsLinks">
			<span>
				{this.props.item.site && 
					<a href={this.props.item.site} >
						<FontAwesomeIcon icon="link" />
					</a>
				}
			  	{this.props.item.mapLink && 
			  		<a href={this.props.item.mapLink} >
			  			<FontAwesomeIcon icon="map" />
			  		</a>
			  	}
			</span>
		</div>
    )
  }
}

export default RankItemLinks;