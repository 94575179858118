
export function editList(id) {
	return '/edit/' + id;
}

export function newAnonList() {
	return "/edit/new";
}

export function goToLogin(redirectUrl) {
	var url = "/login";
	if(redirectUrl) {
		url += "?redirect=" + redirectUrl;
	}
	return url;
}

export function goToSignUp(redirectUrl) {
	var url = "/signup";
	if(redirectUrl) {
		url += "?redirect=" + redirectUrl;
	}
	return url;
}

export function goToDiscoverItem() {
	return "/discoverItem";
}

export function goToViewList(listId) {
	return "/view/"+listId;
}

export function gotToEditList(listId) {
	return "/edit/"+listId;
}

export function goToFavorites() {
	return "/favorites";
}

export function goToAccountSettings() {
	return "/accountSettings";
}

export function goToUserHome(username) {
	return "/userHome/" + username;
}

export function goToVotedRank(rankId) {
	return "/votedRank/" + rankId;
}