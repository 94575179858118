function RankListDeseralize(data) {
	var rankListPreview = {};
	if(data && data.list_body && data.list_body.rankDetails) {
		rankListPreview.name = data.list_body.rankDetails.name;
		rankListPreview.image = data.list_body.rankDetails.imageUrl;
	}
	rankListPreview.author = data.list_body.author;
	rankListPreview.last_updated = data.last_updated;
	// rankListPreview.image = "https://picsum.photos/id/" + getRandomInt(75) + "/200/300";
	rankListPreview.id = data.id;

	return rankListPreview;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export default RankListDeseralize; 