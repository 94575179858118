import React from 'react';

class RankItemMedia extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <img src={this.props.item.image} />
    )
  }
}

export default RankItemMedia;