import React from 'react';
import {
  Redirect,
} from "react-router-dom";
import LinkButton from '../Utility/LinkButton';
import RankListsView from "../RankList/RankListsView";
import logo from '../MyRankLogo.png'
import {newAnonList} from "../Utility/RouteBuilder";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.onListClicked = this.onListClicked.bind(this);
    this.state = {
      newRankId: ''
    }
  }

  onListClicked(item) {
    this.setState({newRankId: item.id});
  }

  render() {
    if (this.state.newRankId !== '') {
      return <Redirect push to={'/view/' + this.state.newRankId} />
    }

    return (
        <div>
          <div className="content">
              <RankListsView 
                favorites={true}
                onListClicked={this.onListClicked} />
          </div>
        </div>
    )
  }
}

export default Header;
