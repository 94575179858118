import React from 'react';
import {
  Router,
  Link
} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { UserState } from '../User/UserState';

class RankListPreview extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(item) {

    if(this.props.onListClicked) {
      this.props.onListClicked(item);
    }
  }

  render() {
    return (
        <div className="rankListPreview"
          style={
            { backgroundImage: "url(" + this.props.item.image + ")"}
          } 
          onClick={this.onClick.bind(this, this.props.item)}
          >
          <div className="background-image" 
          style={
            { backgroundImage: "url(" + this.props.item.image + ")"}
          } />
          <div className="rankListPreviewHero">
            <h3 className="rankListName">{this.props.item.name}</h3>
          </div>
          <div>Author: {this.props.item.author}</div>

          {
            this.props.showeditOptions && 
            <EditFunctions 
              item={this.props.item} 
              deleteList={this.props.deleteList}  
              uploadImage={this.props.uploadImage}
              hideDelete={UserState().discovery_list == this.props.item.id}
            />
          }

        </div>
    )
  }
}

function EditFunctions(props) {
  return (
      <div className="rankListPreviewActions">
            <button className="btn-success  rankListPreviewAction">
              <Link to={"/edit/" + props.item.id}>
                Edit List
              </Link>
            </button>
            <button style={{ display: props.hideDelete ? "none" : "block" }} className="btn-danger rankListPreviewAction"
              onClick={props.deleteList.bind(this, props.item)}
            >
                Delete List
            </button>
          </div>
          );
}

export default RankListPreview;