import React from 'react';
import {
  Redirect,
} from "react-router-dom";
import LinkButton from '../Utility/LinkButton';
import RankListsView from "../RankList/RankListsView";
import logo from '../MyRankLogo.png'
import {newAnonList} from "../Utility/RouteBuilder";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.onListClicked = this.onListClicked.bind(this);
    this.state = {
      newRankId: ''
    }
  }

  onListClicked(item) {
    console.log(item);
    this.setState({newRankId: item.id});
  }

  render() {
    if (this.state.newRankId !== '') {
      return <Redirect push to={'/view/' + this.state.newRankId} />
    }

    return (
        <div>
          <div className="container">
            <div>
              <img className="logo-1 large center" src={logo} alt="My Rank View"/>
              <div className="col-12">When a friend asks for recs, you share your list of best to try and worst to avoid ... your RANK VIEW.  Now you get to share how your own picks RANK against each other. Create a new title or select one already published then drag and drop items you have tried from best to worst.   Peruse RANK VIEW lists created by your friends & family, favorite business, or our weighted lists to find insider information on the greatest right now.</div> 
              <div className="row influencerSection">
                <h3 className="col-sm-8">It’s your chance to be an influencer - 
      what is your RANK VIEW?</h3>
                <div className="col-sm-4">
                    <LinkButton 
                      btnTitle="Create List!"
                      url={newAnonList()}
                      btnClasses="btn btn-myRankView btn-lg btn-block createListButton"
                    />
                </div>
              </div>
            </div>
          </div>
          <div className="featured-section">
              <div>
                <div className="myLine" />
                <h2 className="text-center featureHeader">
                  Updated
                </h2>
              </div>

              <div className="content">
                  <RankListsView 
                    featured={true}
                    onListClicked={this.onListClicked} />
              </div>
            </div>
        </div>
    )
  }
}

export default Header;
