import React from 'react';
import {
  Redirect,
  Router,
  Link
} from "react-router-dom";
import RankListPreview from "../RankList/RankListPreview";
import "./UserHome.css";
import RankViewAPI from "../RankViewApi";
import RankListDeseralize from "../RankList/RankListDeseralize";
import ImageUploader from "../Utility/ImageUploader";
import moment from 'moment';
import {getListByParentId, getMyLists} from "../API/RankList";

class Header extends React.Component {

  constructor(props) {
    super(props);
    this.sortByUpdate = this.sortByUpdate.bind(this);
    this.createNewList = this.createNewList.bind(this);
    this.deleteList = this.deleteList.bind(this);
    this.getLists = this.getLists.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.fileUploaded = this.fileUploaded.bind(this);
    //Yuck for this but I don't have a better solution right now.
    this.itemForFileUpload = {};
    this.state = {
      dataLoading: true,
      newRankId: '',
      rankLists: [
      ],
      showUploader: false
    }
  }

  closeModal() {
    this.setState({showUploader: false});
  }

  uploadImage(item) {
    this.itemForFileUpload = item;
    this.setState({showUploader: true});
  }

  fileUploaded(data) {
    var index = this.state.rankLists.indexOf(this.itemForFileUpload);
    var someArray = [...this.state.rankLists];
    someArray[index].image = data.imageUrl;
    this.setState({rankLists: someArray});
  }

  deleteList(list) {
    var url = 'list/' + list.id;

    RankViewAPI(url, 
      {'method': 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.state)}
      , true)
    .then((res) => {
      var index = this.state.rankLists.indexOf(list);
      var someArray = [...this.state.rankLists];
      someArray.splice(index, 1);
      this.setState({rankLists: someArray});
    });
  }

  createNewList() {
    var url = 'list/';
    
    this.setState({dataLoading: true});

    RankViewAPI(url, {'method': 'GET'}, true)
      .then(res => res.json())
      .then((data) => {
        this.setState({newRankId: data.id});
      });
  }

  getLists() {
    getMyLists(this.props.userId)
      .then((data) => {
        var clensedData = [];

        data.forEach((x) => {
          clensedData.push(RankListDeseralize(x));
        })

        this.setState({rankLists: clensedData}, () => {
          this.setState({dataLoading: false});
        });
      })
  }

  sortByUpdate(a, b) {
    return (moment(a.last_updated) < moment(b.last_updated)) ? 1 : -1;
  }

  componentDidMount() {
    this.getLists(this.props.userId);
  }

  render() {
    const items = this.state.rankLists.sort(this.sortByUpdate).map((item, key) =>
        <RankListPreview 
          key={item.id} 
          item={item}
          showeditOptions={true}
          deleteList={this.deleteList}
          uploadImage={this.uploadImage}
        />
    );

    if (this.state.newRankId !== '') {
      return <Redirect push to={'/edit/' + this.state.newRankId} />
    }

    return (

        <div>
          <ImageUploader 
            show={this.state.showUploader}
            closeModal={this.closeModal}
            fileUploaded={this.fileUploaded}
          />
          <div className="container">
            <div className="row influencerSection">
              <p className="blockquote col-sm"> Make lists share them with your friends and family. FIND THE BEST STUFF!! </p>
              <div 
                className="btn btn-myRankView btn-lg btn-block createListButton col-sm"
                onClick={this.createNewList.bind(this)} >Create New List</div>
            </div>
          </div>
          {this.state.dataLoading &&
            <div>Loading Data...</div>
          }
          {!this.state.dataLoading && this.state.rankLists.length == 0 &&
            <div>Create new lists.</div>}
          {!this.state.dataLoading &&  
            <div className="RankListPreviews">{items}</div>
          }
        </div>
    )
  }
}

export default Header;