import React from 'react';
import {
  Link,
  useHistory,
  Redirect
} from "react-router-dom";

class RankViewRouting extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.redirect === true) {
      return <Redirect push to={this.props.url} />
    }

    return (<div></div> );
  }
}

export default RankViewRouting;