import React from 'react';

class ErrorInput extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.validate = this.validate.bind(this);

    this.state = {
      showError: false
    }
  }

  validate(value, regex) {
    var isValid = value.search(regex);
    if(isValid == -1) {
      console.log("Not valid..");
      this.setState({showError: true});
    } else {
      this.setState({showError: false});
      if(this.props.validationProperty) {
        var holder = {}
        holder[this.props.validationProperty] = false;
        this.props.parentState.setState(holder);
      }
    }

    var change = {};
    change[this.props.propertyName] = value;
    this.props.parentState.setState(change);
    if(this.props.onChange) {
      this.props.onChange();
    }
  }

  onFieldChange(e) {
    var value = e.target.value;
    var regex = new RegExp(e.target.pattern);
    this.validate(value, regex);
  }

  render() {
    return (
      <div>
        {this.state.showError && !this.props.hideError &&
          <div className="alert alert-danger">{this.props.message}</div>
        }
        {!this.state.showError && !this.props.hideError && 
          <div style={{marginTop: "25px"}}></div>
        }

        <input placeholder={this.props.placeholder}
          pattern={this.props.pattern}
          onChange={this.onFieldChange.bind(this)}
          value={this.props.value} 
          type={this.props.type}
          />
      </div>
    )
  }
}

export default ErrorInput;