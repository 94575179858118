import React from 'react';
import Modal from 'react-bootstrap/Modal';
import RankViewAPI from "../RankViewApi";


class Feedback extends React.Component {

  constructor(props) {
    super(props);
    this.submitFeedback = this.submitFeedback.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      show: false,
      feedback: ''
    }
  }

  openModal() {
    this.setState({show: true});
  }

  closeModal() {
    this.setState({show: false});
  }

  handleChange(event) {
    this.setState({feedback: event.target.value});
  }

  submitFeedback() {
    var userString = localStorage.getItem("User");
    var username = "";
    if(userString) {
      var userData = JSON.parse(userString);
      username = userData.username;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        text: this.state.feedback,
        browser_properties: {
          location: window.location.href,
          nVer: navigator.appVersion,
          nAgt: navigator.userAgent,
          browserName: navigator.appName,
          username: username
        }

      })
    };

    return RankViewAPI('feedback', requestOptions)
      .then(() => {
        this.setState({show: false, feedback: ''});
      })
  }

  render() {
    return (
        <div>
           <div 
            className="btn btn-success btn-feedback"
            onClick={this.openModal.bind(this)}
           >Feedback</div>

        <Modal show={this.state.show} >
          <Modal.Header closeButton onClick={this.closeModal}>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>
          
          <Modal.Body>
            <textarea 
              placeholder="Enter feedback."
              value={this.state.feedback} 
              onChange={this.handleChange} 
            />
          </Modal.Body>
        
        <Modal.Footer>  
          <div 
            className="btn btn-success"
            onClick={this.submitFeedback}
          > Send Feedback </div>
        </Modal.Footer>
      </Modal>
        </div>
    )
  }
}

export default Feedback;
