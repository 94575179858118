import React from 'react';
import {withRouter} from 'react-router-dom';

var domain = 'https://myrankview-api.herokuapp.com/api-v1/';

function UserAccountError() {
  console.log("Go to Login screen.");
}

function AddAuth(requestOptions) {
  var requestOptionsWithAuth = requestOptions;
  //Need to check if the user object exists;
  var userString = localStorage.getItem("User");
  
  if(!userString) {
    window.location.href = '/login';
  }

  var userData = JSON.parse(userString);
  var authObject = {
      'Authorization': 'Bearer ' + userData.access_token
  }

  requestOptionsWithAuth.headers = Object.assign(requestOptionsWithAuth.headers, authObject);
  return requestOptionsWithAuth;
}

function handleBadCreds(res) {
  if(res.status == 401) {
    localStorage.removeItem("User");
    window.location.href = '/login';
  }
}

//This class is just a wrapper to the API request.
//It will direct the user to the login/Home on failure of request
function RankViewApiRequest(url, options, auth) {
  var requestOptions = options;
  var headers = requestOptions.headers || {};
  requestOptions.headers = Object.assign(headers, {'Content-Type': 'application/json'});
  
  if(auth) {
    requestOptions = AddAuth(requestOptions);
  }
  return fetch(domain + url, requestOptions)
    .then((res) => {
      if(!res.ok) {
        if(auth) {
          handleBadCreds(res);
        }
        throw res;
        return;
      }
      return res;
    });
    
}

export default RankViewApiRequest;