import React from "react";
import Modal from 'react-bootstrap/Modal';
import RankItemRender from "./RankItemRender";

class RankItemSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTxt: ""
    };
    this.onHide = () => { }
    this.itemSelected = this.itemSelected.bind(this);
    this.createEmptyRankItem = this.createEmptyRankItem.bind(this);
  }

  itemSelected(item) {
    this.props.createNewRankItem(item);
    this.props.closeModal();
  }

  handleChange(event) {
    this.setState({ searchTxt: event.target.value });
  }

  createEmptyRankItem() {
    this.props.createNewRankItem({});
    this.props.closeModal();
  }

  render() {
    const items = this.props.searchableItems.filter(item => item.name.toLowerCase().includes(this.state.searchTxt.toLowerCase())).slice(0, 4).map((item, key) =>
          <div key={item.id} onClick={this.itemSelected.bind(this, item)} >
            <RankItemRender 
              editable={false}
                item={item}
                key={item.id}
            />
          </div>
      );

    return (
      <div>
          <Modal className="imageUploader" show={this.props.show} onHide={this.onHide} >
            <Modal.Header closeButton onClick={this.props.closeModal}>
              <Modal.Title>Existing Items</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
               <label>search</label><input onChange={this.handleChange.bind(this)} value={this.state.searchTxt} ></input>
               <div>
                 <div className="read_view">
                  <div>{items}</div>
                </div>
               </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="btn btn-success" onClick={this.createEmptyRankItem.bind(this)}>Create New Item</div>  
            </Modal.Footer>
        </Modal>
      </div>
    );
  }
}


export default RankItemSelector;