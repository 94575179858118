import React from 'react';
import {
  useHistory
} from "react-router-dom";


//Example usage

/*
   <LinkButton 
      btnTitle="Create List!"
      url={"/edit/new"}
      btnClasses="btn btn-primary"
    />
*/

function LinkButton(props) {
  let history = useHistory();

  function handleClick() {
    history.push(props.url);
  }

  return (
    <button type="button" className={props.btnClasses} onClick={handleClick}>
      {props.btnTitle}
    </button>
  );
}

export default LinkButton;