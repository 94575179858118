import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import wretch from "wretch";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCoffee, faLink, faMapMarkedAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';

import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
    history.replace(path);
}

library.add(fas, faCaretDown);
// library.add(fab, faCoffee, faLink, faMapMarkedAlt)

ReactDOM.render(
	<div>
		<div style = {{ marginTop: 100}}>
	    	<Routes />
	    </div>
	</div>,
    document.getElementById('root')
);