import React from 'react';

class EditRankView extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = props.rankDetails;
  }
  handleChange(e) {
    this.props.handleNameChange(e.target.value);
  }
  render() {
    return (
      <div>
        <input className="inputHeader"
          type="string"
          placeholder="Enter List Name"
          onChange={this.handleChange.bind(this)}
          value={this.props.rankDetails.name} />
      </div>
    );
  }
};

export default EditRankView;