import React from 'react';
import LinkButton from '../Utility/LinkButton';
import { newAnonList, goToLogin, goToSignUp } from "../Utility/RouteBuilder";

class RankListActions extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        {this.props.anonymousUser && 
          <div>
           To SAVE or SHARE this List, LOGIN or SIGN-UP 
            <LinkButton
              btnTitle="Login"
              url={goToLogin(newAnonList())}
              btnClasses="btn btn-primary"
            />
            <LinkButton
              btnTitle="Sign Up"
              url={goToSignUp(newAnonList())}
              btnClasses="btn btn-primary"
            />
          </div>
        }

        {!this.props.anonymousUser && 
          <div>
            <div className="container">
            <div className="row influencerSection">
              <p className="blockquote col-sm"> Share this list with your friends and family or the world! </p>
              <LinkButton
                btnTitle="Share"
                url={"/publish/" + this.props.rankListId}
                btnClasses="btn btn-lg btn-block btn-success col-sm"
              />
            </div>
          </div>
          </div>}
      </div>
    )
  }
}

export default RankListActions;