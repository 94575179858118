import React from 'react';
import RankViewAPI from "../RankViewApi";
import "./User.css";
import ErrorInput from "../Utility/ErrorInput";


class AccountSettings extends React.Component {

  constructor(props) {
    super(props);
    this.onFieldChange = this.onFieldChange;
    this.UpdateAccount = this.UpdateAccount.bind(this);
    this.onTermsAccepted = this.onTermsAccepted.bind(this);
    this.state = {
      password: '',
      password2: '',
      currentPassword: '',
      firstName: '',
      lastName: '',
      email: '',

      errorOccured: false,
      saveSuccessful: false,

      currentPasswordError: true,
      emailError: false,
      passwordError: true,
      requestSent: false
    }
  }
  //get-user-info
  componentDidMount() {

    RankViewAPI('get-user-info', {'method': 'GET'}, true)
      .then(res => res.json())
      .then((data) => {
        console.log(data);
        this.setState({
          firstName: data.user_first_name,
          lastName: data.user_last_name,
          email: data.user_email
        })
      });
  }

  UpdateAccount() {
    var data = {
      "first_name": this.state.firstName,
      "last_name": this.state.lastName,
      "email": this.state.email,
      "current_password": this.state.currentPassword
    };

    if(this.state.password) {
      data["new_password"] = this.state.password;
    }

    const requestOptions = {
          method: 'POST',
          body: JSON.stringify(data)
      };

      this.setState({requestSent: true});
      RankViewAPI('update-user', requestOptions, true)
        .then(res => res.json())
        .then((data, err) => {
            console.log(data, err);
            this.setState({saveSuccessful: true, requestSent: false});
        })
        .catch(() => {
          this.setState({errorOccured: true, requestSent: false})
        });
  }

  onTermsAccepted(e) {
     this.setState({
        termsError: !this.state.termsError
    });
  }

  onFieldChange(property, e) {
    let loginObject = {...this.state};
    var value = e.target.value;
    var regex = new RegExp(e.target.pattern);
    var isValid = value.search(regex);
    
    if(isValid == -1) {
      console.log("Not valid..");
    }

    console.log(isValid);
    loginObject[property] = e.target.value;
    this.setState(loginObject);
  }

  render() {
    return (
      <div className="container">
        <div className="card col-sm-12">
          <h1 className="d-flex justify-content-center"> 
            Account Settings
          </h1>
          <div className="card-body">
            <div className="input-group form-group">
              <div className="col-md-12">
                <div>Password required for updating settings.</div>
                <ErrorInput
                  pattern="^.{8,25}$"
                  //message="Password must have be between 8-25 characters"
                  value={this.state.currentPassword}
                  parentState={this}
                  propertyName="currentPassword"
                  placeholder="Current Password *"
                  type="password"
                  hideError={true}
                  validationProperty="currentPasswordError"
                />
              </div>
            </div>

            <div className="input-group form-group">
              <div className="col-md-6">
                <ErrorInput
                  pattern="^.{8,25}$"
                  message="Password must have be between 8-25 characters"
                  value={this.state.password}
                  parentState={this}
                  propertyName="password"
                  placeholder="Password *"
                  type="password"
                />
              </div>
              <div className="col-md-6">
                <ErrorInput
                  pattern={"^" + this.state.password + "$"}
                  message="Passwords don't match"
                  value={this.state.password2}
                  parentState={this}
                  propertyName="password2"
                  placeholder="Retype Password *"
                  validationProperty="passwordError"
                  type="password"
                />
              </div>
            </div>

            <div className="input-group form-group">
              <div className="col-md-6">
                <ErrorInput
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}"
                  message="The email appears incorrect."
                  value={this.state.email}
                  parentState={this}
                  propertyName="email"
                  placeholder="Email *"
                  validationProperty="emailError"
                />
              </div>
            </div>

            <div className="input-group form-group">
              <div className="col-md-6">
                <div style={{marginTop: "25px"}}></div>
                <input placeholder="First Name" onChange={this.onFieldChange.bind(this, 'firstName')} value={this.state.firstName} />
              </div>
              <div className="col-md-6">
                <div style={{marginTop: "25px"}}></div>
                <input placeholder="Last Name" onChange={this.onFieldChange.bind(this, 'lastName')} value={this.state.lastName} />
              </div>
            </div> 


            <div className="input-group form-group justify-content-center ">
              {this.state.requestSent && 
                  <div className="alert alert-success">Request Sent</div>
              }
              {!this.state.requestSent && 
                
                <button 
                    className="btn btn-success btn-lg" 
                    onClick={this.UpdateAccount} 
                    disabled={
                      this.state.usernameError ||
                      this.state.emailError ||
                      (this.state.passwordError && this.state.password) ||
                      this.state.currentPasswordError
                    }
                  >
                    Submit
                  </button>
              }
            </div>
            
            <div>
              <div className="d-flex justify-content-center links">
                
              </div>
            </div>
            {this.state.errorOccured && <div className="alert alert-danger">Error occured</div>}
            {this.state.saveSuccessful && <div className="alert alert-success">Account Updated!</div>}
          </div>
        </div>
      </div>
    )
  }
}

export default AccountSettings;