import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from "react-router-dom";
import { goToVotedRank } from "../Utility/RouteBuilder";

class RankView extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<NavLink to={goToVotedRank(this.props.rankId)}>
				<div className="votedList">
					<div className="alert alert-primary">This List has been voted on check out the results here!</div>
				</div>
			</NavLink>
		)
	}
}

export default RankView;