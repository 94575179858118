
export function UserState() {
	var userString = localStorage.getItem("User");
	var userObject;
	if(userString) {
		userObject = JSON.parse(userString);
	}
	return userObject;
}

var anonListKey = "anonymousList";

export function AnonList() {
	var anonyListStr = localStorage.getItem(anonListKey);
	var listData;
	if(anonyListStr) {
		listData = JSON.parse(anonyListStr);
		console.log(listData);
		listData.rankItems.sort((a,b) => (a.rank > b.rank) ? 1: -1);
	}
	return listData;
}

export function Anon2List() {
	var anonyListStr = localStorage.getItem(anonListKey+2);
	var listData;
	if(anonyListStr) {
		listData = JSON.parse(anonyListStr);
		console.log(listData);
		listData.rankItems.sort((a,b) => (a.rank > b.rank) ? 1: -1);
	}
	return listData;
}

export function SaveAnon2List(data) {
	localStorage.setItem(anonListKey + 2, JSON.stringify(data));
	return;
}

export function SaveAnonList(data) {
	localStorage.setItem(anonListKey, JSON.stringify(data));
	return;
}

export function ClearAnonList() {
	localStorage.removeItem(anonListKey);
}


export function ClearAnon2List() {
	localStorage.removeItem(anonListKey);
}