import React from "react";
import {
  useParams,
  useLocation,
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import EditRankList from "./views/RankEditView.jsx";
import SignUp from "./User/SignUp";
import Login from "./User/Login";
import AccountSettings from "./User/AccountSettings";
import UserHome from "./views/UserHome";
import Header from './views/Header';
import Home from './views/Home';
import PublishRankList from './RankList/PublishRankList';
import RankView from './views/RankView';
import ImageUploader from './Utility/ImageUploader';
import Analytics from 'react-router-ga';
import DiscoverItem from './views/DiscoverItem';
import Favorites from "./views/Favorites";
import VotedRank from "./views/VotedRank";


class Routes extends React.Component {

  constructor(props) {
    super(props);
    this.loggedInCallback = this.loggedInCallback.bind(this);
    var userData = localStorage.getItem("User");
    if(userData !== "undefined") {
      userData = JSON.parse(userData);
    }

    this.state = {
      loggedIn: false,
      userData: userData || {}
    };
  }

  loggedInCallback(resUserData, stateUpdated) {
    this.setState({loggedIn: true, userData: resUserData}, stateUpdated);
  }

  render() {
    return (
      <Router>
        <Analytics id="UA-172968853-1">
          <div>
            <Header 
              loggedIn= {this.state.loggedIn} 
              userData={this.state.userData}
            />
            {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/discoverItem">
                <DiscoverItem />
              </Route>
              <Route path="/edit/:id">
                <EditRankListView />
              </Route>
              <Route path="/login">
                <LoginView 
                  loggedInCallback= {this.loggedInCallback}
                />
              </Route>
              <Route path="/favorites">
                <Favorites />
              </Route>
              <Route path="/accountSettings">
                <AccountSettings />
              </Route>
              <Route path="/SignUp">
                <SingupView 
                  loggedInCallback= {this.loggedInCallback}
                />
              </Route>
              <Route path="/UserHome/:id">
                <UserHomeRankList 
                  userData={this.state.userData}
                />
              </Route>
              <Route path="/publish/:id">
                <PublishView />
              </Route>
              <Route path="/view/:id">
                <ShowRankView />
              </Route>
              <Route path="/upload">
                <ImageUploader />
              </Route>
              <Route path="/VotedRank/:id">
                <ShowVotedRankView />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Analytics>
      </Router>
    )
  }
}

function LoginView(props) {
  let query = new URLSearchParams(useLocation().search);
  return <Login 
      loggedInCallback= {props.loggedInCallback}
      redirect={query.get("redirect")}
    />
}

function SingupView(props) {
  let query = new URLSearchParams(useLocation().search);
  return <SignUp 
      loggedInCallback= {props.loggedInCallback}
      redirect={query.get("redirect")}
    />
}

function PublishView() {
  let {id} = useParams();
  return <PublishRankList rankListId={id} />
}

function ShowVotedRankView(props) {
  let {id} = useParams();
  return <VotedRank rankId={id} />
}

function ShowRankView(props) {
  let {id} = useParams();
  return <RankView rankId={id} />
}

function EditRankListView() {
  let {id} = useParams();
  return <EditRankList rankId={id} />
}

function UserHomeRankList(props) {
  let {id} = useParams();
  console.log(props);
  return <UserHome 
    userData={props.userData}
    userId={id} />
}

export default Routes;
