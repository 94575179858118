import React from 'react';
import ReactDOM from 'react-dom';
import EditRankListDetails from '../RankList/EditRankListDetails';
import EditRankItems from '../RankList/EditRankItems';
import AddRankItemButton from '../RankList/AddRankItemButton';
import { useParams } from "react-router-dom";
import update from 'immutability-helper';
import RankViewAPI from "../RankViewApi";
import RankListActions from "../RankList/RankListActions";
import RankViewRouting from "../Utility/RankViewRouting";
import RankItemRender from "../RankItem/RankItemRender";
import VisitorActions from "../Utility/VisitorActions";
import { getPublicListById } from '../API/RankList';
import VotedRankListNotice from '../RankList/VotedRankListNotice';

class RankView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			dataLoading: true,
			rankListId: props.rankId,
			rankDetails: {
				name: ''
			},
			rankItems: [
			],
			rawData: {},
			parentRankId: ""
		}
	}

	componentDidMount() {
		getPublicListById(this.state.rankListId)
			.then(this.dataTostate.bind(this));
	}

	dataTostate(data) {
		data.list_body.rankItems.sort((a,b) => (a.rank > b.rank) ? 1: -1);
		this.setState(data.list_body, () => {
			this.state.dataLoading = false;
		});
		this.setState({rankListId: data.id});
		this.setState({rawData: data});
		var parentRankId = (data.list_body.parentId) ? data.list_body.parentId : data.id;
		this.setState({parentRankId: parentRankId});
		//This rankView have stackSquash?
	}
	
	render() {
		const items = this.state.rankItems.map((item, key) =>
	        <RankItemRender 
	        	editable={false}
	            item={item}
	            key={item.id}
	        />
	    );

		return (
			<div className="rankList">
				<VotedRankListNotice rankId={this.state.parentRankId} />
				<h1> 
					{this.state.rankDetails.name} 
				</h1>
				
				<div className="read_view">
					<div>{items}</div>
				</div>

				<VisitorActions data={this.state.rawData} rankViewId={this.state.rankListId}  />
			</div>
		)
	}
}

export default RankView;