import React, { Component } from "react";
import ReactDOM from "react-dom";
import RankItemRender from "../RankItem/RankItemRender";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CSSTransition } from "react-transition-group";

const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
});

class EditRankItemView extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.itemClicked = this.itemClicked.bind(this);
    this.clearEditIndex = this.clearEditIndex.bind(this);
    this.state = {
      editIndex: -1
    }
    
  }

  itemClicked(index, el) {
    if(index != this.state.editIndex) {
      console.log(".....");
      this.setState({editIndex: index});
      // console.log(el.target);
      setTimeout(() => {
        if(document.getElementsByClassName("editCenter")[0]) {
          document.getElementsByClassName("editCenter")[0].scrollIntoView({ behavior: "smooth", block: "center" })
        }
      }, 100);
      // document.getElementsByClassName("editBottom")[0].scrollIntoView(false, { behavior: "smooth" })
      // document.getElementsByClassName("editBottom").scrollIntoView({ behavior: "smooth" });
      // el.target.scrollIntoView({ behavior: "smooth" });
    }
  }

  onDragEnd(result) {
    
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.props.rankItems,
      result.source.index,
      result.destination.index
    );

    console.log(items);

    this.props.handleItemRankChange(items);
  }

  clearEditIndex() {
    this.setState({editIndex: -1});
  }

  onDragStart(result) {
    this.clearEditIndex();
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    return (
      <DragDropContext 
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
        >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {this.props.rankItems.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          onClick={this.itemClicked.bind(this, index)}
                          className="rankItemParent"
                      >
                      <RankItemRender 
                        editable={(this.state.editIndex == index)}
                        rankItemChanged={this.props.rankItemChanged}
                        item={item}
                        clearEditIndex={this.clearEditIndex}
                        removeRankItem={this.props.removeRankItem}
                      />    
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}


export default EditRankItemView;