import React from 'react';
import {
  Redirect,
  Router,
  Link
} from "react-router-dom";
import RankListPreview from "../RankList/RankListPreview";
import "../views/UserHome.css";
import RankViewAPI from "../RankViewApi";
import RankListDeseralize from "./RankListDeseralize";
import moment from 'moment';

class RankListsView extends React.Component {

  constructor(props) {
    super(props);
    this.sortByUpdate = this.sortByUpdate.bind(this);
    this.listClicked = this.listClicked.bind(this);
    this.state = {
      dataLoading: true,
      error: '',
      newRankId: '',
      rankLists: [
      ]
    }
  }

  listClicked(item) {
    if(this.props.listClicked) {
      this.props.listClicked(item);
    }
  }

  componentDidMount() {
    const requestOptions = {
          method: 'GET'
      };

    var request;

    if(this.props.featured) {
      request = RankViewAPI.bind(this, "lists/featured", requestOptions);
    } else if (this.props.favorites) {
      request = RankViewAPI.bind(this, "lists/favorite", requestOptions, true);
    } else {
      request = RankViewAPI.bind(this, "lists/" + this.props.userId, requestOptions, true);
    }

    request()
      .then(res => res.json())
      .then((data) => {
        var clensedData = [];

        var count = 0;
        data.forEach((x) => {
          clensedData.push(RankListDeseralize(x));
        })

        this.setState({rankLists: clensedData}, () => {
          this.setState({dataLoading: false});
        });
      })
      .catch(() => {
        this.setState({dataLoading: false, error: "An error occured getting lists."});
      })
  }

  sortByUpdate(a, b) {
    return (moment(a.last_updated) < moment(b.last_updated)) ? 1 : -1;
  }

  render() {
    const items = this.state.rankLists.sort(this.sortByUpdate).map((item, key) => 
          <RankListPreview 
            key={item.id + Math.random() + Math.random()} 
            item={item}
            onListClicked={this.props.onListClicked} 
          />
    );

    if (this.state.newRankId !== '') {
      return <Redirect push to={'/edit/' + this.state.newRankId} />
    }

    return (

        <div>
          {this.state.dataLoading &&
            <div>Loading Data...</div>
          }
          {!this.state.dataLoading &&
            <div className="RankListPreviews">{items}</div>
          }
          {!this.state.dataLoading && this.state.error != "" &&
          <div className="alert alert-danger">{this.state.error}</div>}
        </div>
    )
  }
}

export default RankListsView;