import React from 'react';
import Button from 'react-bootstrap/Button';
import {Typeahead, AsyncTypeahead} from 'react-bootstrap-typeahead';
import RankItemPriceEdit from './RankItemPriceEdit';
import './RankItemEdit.css';
import ImageUploader from "../Utility/ImageUploader";
import RankItemMedia from './RankItemMedia';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class RankItemEditCondensed extends React.Component {

  constructor(props) {
    super(props);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.exitEditMode = this.exitEditMode.bind(this);
    this.removeRankItem = this.removeRankItem.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.fileUploaded = this.fileUploaded.bind(this);
    this.toggleLinks = this.toggleLinks.bind(this);
    this.toggleAddress = this.toggleAddress.bind(this);
    this.togglePricing = this.togglePricing.bind(this);
    this.clearImage = this.clearImage.bind(this);
    this.state = {
      showUploader: false,
      showLinks: false,
      showAddress: false,
      showPricing: false
    }
  }

  clearImage() {
    var event = {};
    event.target = {value: ""}
    this.handleNameChange('image', event);
  }

  togglePricing() {
    this.setState({showPricing: !this.state.showPricing })
  }

  toggleAddress() {
    this.setState({showAddress: !this.state.showAddress })
  }

  toggleLinks() {
    this.setState({showLinks: !this.state.showLinks })
  }

  closeModal() {
    this.setState({showUploader: false});
  }

  uploadImage() {
    this.setState({showUploader: true});
  }

  fileUploaded(data) {
    var event = {};
    event.target = {value: data.imageUrl}
    this.handleNameChange('image', event);
  }

  exitEditMode() {
    this.props.clearEditIndex();
  }

  handleNameChange(field, e) {
    let newItem = {...this.props.item};
    newItem[field] = e.target.value;
    this.props.rankItemChanged(this.props.item, newItem);
  }

  removeRankItem(item) {
    this.props.removeRankItem(item);
    this.props.clearEditIndex();
  }

  render() {
    return (
      <div>
        <ImageUploader 
            show={this.state.showUploader}
            closeModal={this.closeModal}
            fileUploaded={this.fileUploaded}
          />
        
        <div className="container">
          <div className="row mb-2">
            <label className="col-2">Name *</label>
            <input className="col-10" placeholder="Click To Edit" onChange={this.handleNameChange.bind(this, 'name')} value={ this.props.item.name == "Click To Edit" ? "" : this.props.item.name } />
          </div>
          <div className="row mb-2">
            {(!this.props.item.image || this.props.item.image == "") && 
              <div 
                className="col-3 btn btn-primary imageUploadBtn"
                onClick={this.uploadImage}
              >Upload Image</div>
            }

            {(this.props.item.image && this.props.item.image != "") &&
              <div className="col-3 thumbnailImage">
                <RankItemMedia item={this.props.item}/>
                <div className="btn btn-danger removeMedia" onClick={this.clearImage.bind(this)}>X</div>
              </div>
            }

            <textarea className="col-8 offset-1" onChange={this.handleNameChange.bind(this, 'description')} value={this.props.item.description}  placeholder="Enter description" />
          </div>
          <div className="editCenter"></div>
          
          <div onClick={this.toggleLinks.bind(this)}>Links <FontAwesomeIcon icon={this.state.showLinks ? "caret-down" : "caret-right"} /></div>
          
          <div style={{display: this.state.showLinks ? 'block' : 'none' }}>
            <div className="row mb-2">
              <label className="col-6 text-center" >Website</label>
              <label className="col-6 text-center">Google Map Link</label>
            </div>
            <div className="row mb-2">
              <input className="col-6" onChange={this.handleNameChange.bind(this, 'mapLink')} value={this.props.item.mapLink} />
              <input className="col-6" onChange={this.handleNameChange.bind(this, 'site')} value={this.props.item.site} />
            </div>
          </div>

          <div onClick={this.toggleAddress.bind(this)}>Address <FontAwesomeIcon icon={this.state.showAddress ? "caret-down" : "caret-right"} /></div>
          
          <div style={{display: this.state.showAddress ? 'block' : 'none' }}>

            <div className="row">
              <label className="col-6 text-center">Address</label>
              <label className="col-6 text-center">State</label>
            </div>
            <div className="row"> 
              <input className="col-6" onChange={this.handleNameChange.bind(this, 'address')} value={this.props.item.address}  />
              <input className="col-6" onChange={this.handleNameChange.bind(this, 'state')} value={this.props.item.state}  />
            </div>
            <div className="row">
              <label className="col-6 text-center">City</label>
              <label className="col-6 text-center">ZipCode</label>
            </div>
            <div className="row">
              <input className="col-6" onChange={this.handleNameChange.bind(this, 'city')} value={this.props.item.city}  />
              <input className="col-6" onChange={this.handleNameChange.bind(this, 'zip')} value={this.props.item.zip}  />
            </div>
          </div>

          <div onClick={this.togglePricing.bind(this)}>Pricing <FontAwesomeIcon icon={this.state.showPricing ? "caret-down" : "caret-right"} /></div>
          
          <div style={{display: this.state.showPricing ? 'block' : 'none' }}>
            <RankItemPriceEdit 
            item={this.props.item}
            rankItemChanged={this.props.rankItemChanged}
            />
          </div>

          <div>
            <Button onClick={this.exitEditMode.bind(this)}> Close </Button>
            <Button className="btn-danger float-right removeItem" onClick={this.removeRankItem.bind(this, this.props.item)}>Remove</Button>
         </div>
        </div>
      </div>
    )
  }
}

export default RankItemEditCondensed;