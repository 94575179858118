import React from 'react';

class RankItemPriceEdit extends React.Component {

  constructor(props) {
    super(props);
    this.pricingClicked = this.pricingClicked.bind(this);
    this.state = {
      pricing: this.props.item.pricing
    }
  }

  pricingClicked(newPrice) {
    let newItem = {...this.props.item};
    newItem.pricing = newPrice; 
    this.props.rankItemChanged(this.props.item, newItem);
    this.setState({pricing: newPrice});
  }

  render() {
    return (
      <div className="rankItemPriceEditor">
        <label>Pricing</label>
        <div>
          <span onClick={this.pricingClicked.bind(this, 1)} className={this.state.pricing >= 1 ? 'priceSelected' : 'priceNotSelected'}>$</span>
          <span onClick={this.pricingClicked.bind(this, 2)} className={this.state.pricing >= 2 ? 'priceSelected' : 'priceNotSelected'}>$</span>
          <span onClick={this.pricingClicked.bind(this, 3)} className={this.state.pricing >= 3 ? 'priceSelected' : 'priceNotSelected'}>$</span>
          <span onClick={this.pricingClicked.bind(this, 4)} className={this.state.pricing >= 4 ? 'priceSelected' : 'priceNotSelected'}>$</span>
          <span onClick={this.pricingClicked.bind(this, 5)} className={this.state.pricing >= 5 ? 'priceSelected' : 'priceNotSelected'}>$</span>
        </div>
      </div>
    )
  }
}

export default RankItemPriceEdit;