import React from 'react';
import {Redirect} from 'react-router-dom';
import RankViewAPI from "../RankViewApi";
import "./User.css";
import RankViewRouting from "../Utility/RankViewRouting";
import ErrorInput from "../Utility/ErrorInput";


class SignUp extends React.Component {

  constructor(props) {
    super(props);
    this.onFieldChange = this.onFieldChange;
    this.CreateAccount = this.CreateAccount.bind(this);
    this.onTermsAccepted = this.onTermsAccepted.bind(this);
    this.clearSecondPassword = this.clearSecondPassword.bind(this);
    this.validators = [];
    this.state = {
      username: '',
      password: '',
      toUserLogin: false,
      errorOccured: false,
      password2: '',
      firstName: '',
      lastName: '',
      referrer: '',
      email: '',
      userId: 0,
      usernameError: true,
      emailError: true,
      termsError: true,
      passwordError: true,
      errorMessage: "An Error Occured"
    }
  }

   clearSecondPassword() {
    this.setState({password2: ""});
  }

  CreateAccount() {
    console.log(this.state.email);
    const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "first_name": this.state.firstName,
            "last_name": this.state.lastName,
            "username": this.state.username,
            "password": this.state.password,
            "email": this.state.email,
            "additional_info": {
              "referrer": this.state.referrer
            }
          })
      };

      RankViewAPI('register', requestOptions, false)
        .then(res => res.json())
        .then((data) => {
            if(!data || !data.username) {
              Promise.reject("User Data is not valid");
            }
            localStorage.setItem("User", JSON.stringify(data));
            this.props.loggedInCallback(data,
              () => {
                var url = this.state.url || ('/userHome/' + data.username);
                this.setState({
                  userId: data.username, 
                  toUserLogin: true,
                  url: url,
                  redirect: true
                });
            });
          })
        .catch((err) => {
          // const reader = err.body.getReader();
          this.setState({errorOccured: true});
          
          if(err.status == 400) {
            err.json()
            .then((res) => {
              this.setState({errorMessage: res.msg});
            })
          }
        });
  }

  onTermsAccepted(e) {
     this.setState({
        termsError: !this.state.termsError
    });
  }

  onFieldChange(property, e) {
    let loginObject = {...this.state};
    var value = e.target.value;
    var regex = new RegExp(e.target.pattern);
    var isValid = value.search(regex);
    
    if(isValid == -1) {
      console.log("Not valid..");
    }

    console.log(isValid);
    loginObject[property] = e.target.value;
    this.setState(loginObject);
  }

  render() {
    return (
      <div className="container">
        <RankViewRouting 
          url={this.state.url}
          redirect={this.state.redirect}
        />

        <div className="card col-sm-12">
          <h1 className="d-flex justify-content-center"> 
            Sign Up
          </h1>
          <div className="card-body">
            <div className="input-group form-group">
              <div className="col-md-6">
                <ErrorInput
                  pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}"
                  message="The email appears incorrect."
                  value={this.state.email}
                  parentState={this}
                  propertyName="email"
                  placeholder="Email *"
                  validationProperty="emailError"
                />
                </div>
              <div className="col-md-6">
                <ErrorInput
                  pattern="^.{4,15}$"
                  message="Must be 4-15 characters."
                  value={this.state.username}
                  parentState={this}
                  propertyName="username"
                  placeholder="User Name *"
                  validationProperty="usernameError"
                />
                </div>
            </div>

            <div className="input-group form-group">
              <div className="col-md-6">
                <ErrorInput
                  pattern="^.{8,25}$"
                  message="Password must have be between 8-25 characters"
                  value={this.state.password}
                  onChange={this.clearSecondPassword.bind(this)}
                  parentState={this}
                  propertyName="password"
                  placeholder="Password *"
                  type="password"
                />
              </div>
              <div className="col-md-6">
                <ErrorInput
                  pattern={"^" + this.state.password.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + "$"}
                  message="Passwords don't match"
                  value={this.state.password2}
                  parentState={this}
                  propertyName="password2"
                  placeholder="Retype Password *"
                  validationProperty="passwordError"
                  type="password"
                />
              </div>
            </div>

            <div className="input-group form-group">
              <div className="col-md-6">
                <div style={{marginTop: "25px"}}></div>
                <input placeholder="First Name" onChange={this.onFieldChange.bind(this, 'firstName')} value={this.state.firstName} />
              </div>
              <div className="col-md-6">
                <div style={{marginTop: "25px"}}></div>
                <input placeholder="Last Name" onChange={this.onFieldChange.bind(this, 'lastName')} value={this.state.lastName} />
              </div>
            </div>

            <div className="input-group form-group">
              <div className="col-md-12">
                <input placeholder="How you hear about us?" type="text" onChange={this.onFieldChange.bind(this, 'referrer')} value={this.state.referrer} />
              </div>
            </div>
            

            <div className="input-group radio-inline justify-content-center">
                <label  className="radio-inline">
                  <input
                    value="true"
                    checked={!this.state.termsError} 
                    onChange={this.onTermsAccepted.bind(this)}
                    type="checkbox" 
                    className="sq-form-field mr-2" /> 
                  Accept <a target='_blank' href="terms"> terms </a> 
                  and conditions.
                </label>
            </div>

            <div className="input-group form-group justify-content-center ">
              <button 
                  className="btn btn-success btn-lg" 
                  onClick={this.CreateAccount} 
                  disabled={
                    this.state.usernameError ||
                    this.state.emailError ||
                    this.state.termsError ||
                    this.state.passwordError
                  }
                >
                  Submit
                </button>
            </div>
            
            <div>
              <div className="d-flex justify-content-center links">
                
              </div>
            </div>
            {this.state.errorOccured && <div className="alert alert-danger">{this.state.errorMessage}</div>}
          </div>
        </div>
      </div>
    )
  }
}

export default SignUp;