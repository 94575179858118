import RankViewAPI from "../RankViewApi";

export function unpublishRankList(rankListId) {
	const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"list_id":rankListId})
    };

	return RankViewAPI('/unpublish-list', requestOptions, true)
		.then(res => res.json());
}

export function createNewList() {
	var url = 'list/';

    return RankViewAPI(url, {'method': 'GET'}, true)
		.then(res => res.json());
}

export function createNewListWithData(data) {
	return createNewList()
		.then((res) => saveList(data, res.id));
}

export function getMyListById(rankListId) {
	 var url = 'list/'+rankListId;
	return RankViewAPI(url, {'method': 'GET'}, true)
      .then(res => res.json())
}

export function getPublicListById(rankListId) {
	var url = 'list/shared-view/'+rankListId;
	return RankViewAPI(url, {'method': 'GET'})
			.then(res => res.json());
}

export function getStackSquash(rankListId) {
	var url = 'lists/stacksquash/'+rankListId;
	return RankViewAPI(url, {'method': 'GET'})
			.then(res => res.json());
}

export function saveList(data, id) {
	const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    };

	return RankViewAPI('list/'+id, requestOptions, true)
		.then(res => res.json());
}

export function getListByParentId(parentId, userId) {
	return getMyLists(userId)
		.then(lists => lists.find(x => x.list_body.parentId == parentId));
}

export function getMyLists(userId) {
    return RankViewAPI("lists/" + userId, {'method': 'GET'}, true)
    	.then(res => res.json());
}

export function cloneList(data) {
	return createNewList()
	  .then((res) => { 
	  	data.list_body.parentId = data.id;
	  	return saveList(data.list_body, res.id);
	  });
}

export function lists_Favorite(id) {
	console.log("Favorite this list");
	const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "list_id" : id })
    };

    return RankViewAPI('lists/favorite', requestOptions, true)
		.then(res => res.json());
}

export function lists_Unfavorite(id) {
	const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "list_id" : id })
    };

    return RankViewAPI('lists/unfavorite', requestOptions, true)
		.then(res => res.json());
}

export function lists_Clone(id) {
	const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "list_id" : id })
    };

    return RankViewAPI('lists/clone', requestOptions, true)
		.then(res => res.json());
}

export function lists_IsFavorite(id) {
	var url = 'lists/favorite/' + id;

	return RankViewAPI(url, {'method': 'GET'}, true)
		.then(res => res.json());
}