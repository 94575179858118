import React from 'react';
import RankItem from './RankItem_Insta';
import RankItemEdit from '../RankItem/RankItemEditCondensed';

import { UserState } from '../User/UserState';

class RankItemRender extends React.Component {

  constructor(props) {
    super(props);
    this.checkUserIsOwner = this.checkUserIsOwner.bind(this);
  }

  checkUserIsOwner() {
    if(this.props.item.author == "") {
      return true;
    }
    
    if(!UserState()) {
      return false;
    }

    return UserState().username == this.props.item.author;
  }

  render() {
    return (
      <div className="aRankItem">
           {this.checkUserIsOwner() && this.props.editable &&
                
              <RankItemEdit 
                item={this.props.item} 
                rankItemChanged={this.props.rankItemChanged}
                clearEditIndex={this.props.clearEditIndex}
                removeRankItem={this.props.removeRankItem}
              />
            }
            {(!this.checkUserIsOwner() || this.props.editable == false) &&
              <RankItem 
                item={this.props.item} 
              />
            }
      </div>
    )
  }
}

export default RankItemRender;