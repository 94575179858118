import React from 'react';
import RankItemAddress from './RankItemAddress';
import RankItemPricing from './RankItemPricing';
import RankItemMedia from './RankItemMedia';
import RankItemLinks from './RankItemLinks';
import './RankItem.css'
import ReadMoreReact from 'read-more-react';

class RankItem extends React.Component {

  constructor(props) {
    super(props);
    this.readMore = this.readMore.bind(this);
    var description = props.item.description || ""
    var hasExtendedDetails = typeof this.props.item.address !== 'undefined' ||
        typeof this.props.item.city !== 'undefined' ||
        typeof this.props.item.mapLink !== 'undefined' ||
        typeof this.props.item.pricing !== 'undefined' ||
        typeof this.props.item.site !== 'undefined' ||
        typeof this.props.item.state !== 'undefined' ||
        typeof this.props.item.zip !== 'undefined' ||
        description.length > 150;

    var truncated = description;
    if(description.length > 150) {
       truncated = description.substring(0, description.lastIndexOf(" ", 150))
    }
    this.state = {
      showDetails: false,
      rawDescription: description,
      truncated:  truncated,
      showReadMore: hasExtendedDetails
    }
  }

  readMore() {
    this.setState({showReadMore: false, showDetails: true});
  }

// style={{display: this.state.showDetails || this.state.showReadMore ? 'none' : 'block' }}
  render() {
    return (
      <div className="rankItem insta">
        <div>
          <div className="rankItemDetails" >
            <div className="rankItemHeader">
              <h4>
                <span>{this.props.item.rank}.  {this.props.item.name}</span>
              </h4>
            </div>
              <RankItemMedia item={this.props.item}/>
              <div>
                {this.state.showDetails ? this.state.rawDescription : this.state.truncated}
              </div>
              <div style={{display: this.state.showDetails ? 'block' : 'none' }}>
                <div className="rankItemSubDetails">
                  <RankItemAddress item={this.props.item} />
                  <RankItemPricing item={this.props.item} />
                </div>
                <RankItemLinks item={this.props.item} />
              </div>

              {(this.state.showReadMore) && 
                <div className="badge badge-primary" onClick={this.readMore.bind(this)}>
                  Read More...
                </div>
              }
          </div>
        </div>
      </div>
    )
  }
}

export default RankItem;