import React from "react";
import ImageUploader from "react-images-upload";
import Modal from 'react-bootstrap/Modal';
import RankViewAPI from "../RankViewApi";

class ImageUploaderBah extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pictures: [] };
    this.onDrop = this.onDrop.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.onHide = () => { }
    this.state = {
      show: true,
      pictures: []
    }
    this.uploadDetalis = {
      "uuid": "",
      "upload_complete": "True",
      "url": ""
    }
    this.setupUpload = this.setupUpload.bind(this);
    this.upload = this.upload.bind(this);
    this.notifyServer = this.notifyServer.bind(this);
    this.uploadComplete = this.uploadComplete.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState({
      pictures: this.state.pictures.concat(pictureFiles)
    });
  }

  setupUpload(x) {
    return RankViewAPI(
      "upload?filename="+x.name+"&content_type=" + x.type, 
      {'method': 'GET'}, true)
  }

  upload(x, res) {
    this.uploadDetalis.uuid = res.uuid;
    this.uploadDetalis.url = res.url;
    
    //Setup Post Data;
    var postData = new FormData();
    var key;
    for(key in res.data.fields){
      postData.append(key, res.data.fields[key]);
    }
    postData.append('file', x)

    //Get logged in user data.
    var userString = localStorage.getItem("User");
    if(!userString) {
      window.location.href = '/login';
    }
    var userData = JSON.parse(userString);
    
    //Do Post
    return fetch("https://s3.us-west-1.amazonaws.com/images.myrankview.com", {
        method: 'POST',
        body: postData,
        'Authorization': 'Bearer ' + userData.access_token
    })
  }

  notifyServer() {
    return RankViewAPI("upload",
      {
        'method': 'POST',
        body: JSON.stringify(this.uploadDetalis) 
      }, 
      true);
  }

  uploadComplete() {
    if(this.props.fileUploaded) {
          this.props.fileUploaded({imageUrl: this.uploadDetalis.url});
        }
        this.props.closeModal();
  }

  uploadFiles() {
    this.state.pictures.forEach(x => {
      this.setupUpload(x)
        .then(res => res.json())
        .then(this.upload.bind(this, x))
        .then(this.notifyServer)
        .then(this.uploadComplete);
    });
  }

  render() {
    return (
      <div>
          <Modal className="imageUploader" show={this.props.show} onHide={this.onHide} >
            <Modal.Header closeButton onClick={this.props.closeModal}>
              <Modal.Title>Image Uploader</Modal.Title>
            </Modal.Header>
            
            <Modal.Body>
               <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={this.onDrop}
                  imgExtension={[".jpg", ".gif", ".png", ".jpeg"]}
                  maxFileSize={5242880}
                  withPreview={true}
                  singleImage={true}
                />
            </Modal.Body>
          
          <Modal.Footer>  
            <div 
              className="btn btn-success"
              onClick={this.uploadFiles}
            > Upload </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}


export default ImageUploaderBah;