import React from 'react';
import "./VisitorActions.css";
import { UserState, AnonList, SaveAnonList, SaveAnon2List } from '../User/UserState';
import { createNewList, cloneList, lists_Favorite, lists_Unfavorite, lists_IsFavorite, getListByParentId } from '../API/RankList';
import { editList, newAnonList, goToLogin, goToSignUp, goToViewList } from "./RouteBuilder";
import RankViewRouting from "./RankViewRouting";
import AccountRequire from "./AccountRequire";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class VisitorActions extends React.Component {

  constructor(props) {
    super(props);
    this.reRank = this.reRank.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.follow = this.follow.bind(this);
    this.followApiCall = this.followApiCall.bind(this);
    this.cloneANewList = this.cloneANewList.bind(this);
    this.state ={
      url: '',
      redirect: '',
      showAccountModal: false,
      accountMessage: 'Account is required to complete this action.',
      following: false,
      accountRedirectUrl: ''
    }
  }

  follow() {
    if(UserState()) {
      var followToggled = !this.state.following;
      this.followApiCall(this.props.data.id, followToggled);
      this.setState({"following": followToggled});
    } else {
      this.setState({
          showAccountModal: true,
          accountRedirectUrl: goToViewList(this.props.data.id)
        });
    }
  }

  followApiCall(listId, following) {
    (following) ? lists_Favorite(listId) : lists_Unfavorite(listId);
  }

  closeModal() {
    this.setState({showAccountModal: false});
  }

  cloneANewList() {
    cloneList(this.props.data)
      .then((res) => {
        var url = editList(res.id);
        this.setState({
          url: url,
          redirect: true
        })
      });
  }

  reRank() {
    var user = UserState();
    if(user) {

      var listId = (this.props.data.list_body.parentId) ? this.props.data.list_body.parentId : this.props.data.id;
      getListByParentId(listId, user.username)
      .then((listThatHasParent) => {
        if(listThatHasParent) {
          this.setState({"url":editList(listThatHasParent.id),"redirect":true});
        } else {
          this.cloneANewList()
        }
      });
    } else {
      var localList = AnonList();
      delete this.props.data.list_body.anonymousUser;
      if(localList) {
        SaveAnon2List(localList);
        SaveAnonList(this.props.data.list_body);
        this.setState({
          accountRedirectUrl: newAnonList(),
          showAccountModal: true
        });
      } else {
        SaveAnonList(this.props.data.list_body);
        this.setState({
          url: newAnonList(),
          redirect: true
        });
      }
    }
  }

  componentDidMount() {
    if(UserState()) {
      lists_IsFavorite(this.props.rankViewId)
        .then((data) =>  {
          this.setState({
            following: data.is_favorite
          })
        });
    }
  }

  render() {
    return (
        <div className="vistorActionsContainer">
          <AccountRequire 
            show={this.state.showAccountModal} 
            message={this.state.accountMessage}
            loginUrl={goToLogin(this.state.accountRedirectUrl)}
            singUpUrl={goToSignUp(this.state.accountRedirectUrl)}
            closeModal={this.closeModal.bind(this)} />
          <RankViewRouting 
              url={this.state.url}
              redirect={this.state.redirect}
            />
          <div className="reRankContainer btn btn-warning" onClick={this.reRank.bind(this)}>
              <span className="showVisitorLabel">ReRank!</span>
              <div className="re-rank">
                <FontAwesomeIcon icon="layer-group" /> 
              </div> 
          </div>
          <div className="followContainer btn btn-primary"  onClick={this.follow.bind(this)}>
            <span className="showVisitorLabel">Follow</span>
            <span className={this.state.following ? "heart_selected" : "heart"}>
              <FontAwesomeIcon icon="heart" />
            </span>
          </div>

        </div>
    )
  }
}

export default VisitorActions;
