import React from 'react';
import ReactDOM from 'react-dom';

import EditRankListDetails from '../RankList/EditRankListDetails';
import EditRankItems from '../RankList/EditRankItems';
import AddRankItemButton from '../RankList/AddRankItemButton';
import RankListActions from "../RankList/RankListActions";

import RankViewRouting from "../Utility/RankViewRouting";
import ImageUploader from "../Utility/ImageUploader";
import RankItemSelector from "../RankItem/RankItemSelector";

import { uuidv4, testUUID } from '../Utility/uuidv4';

import { UserState, 
		AnonList, 
		Anon2List, 
		SaveAnonList, 
		ClearAnonList, 
		ClearAnon2List } from '../User/UserState';

import { createNewListWithData, 
			getMyListById, 
			saveList, 
			createNewList,
			getStackSquash,
			unpublishRankList } from '../API/RankList';

function GetLocalSavedList() {
	var anonyListStr = localStorage.getItem("anonymousList");
	if(anonyListStr) {
		var data = JSON.parse(anonyListStr);
		data.rankItems.sort((a,b) => (a.rank > b.rank) ? 1: -1);
		return data;
	}
}

class RankEditView extends React.Component {
	constructor(props) {
		super(props);
		this.saveList = this.saveList.bind(this);
		this.delaySave = this.delaySave.bind(this);
		this.uploadImage = this.uploadImage.bind(this);
		this.unpublish = this.unpublish.bind(this);
	    this.closeModal = this.closeModal.bind(this);
	    this.fileUploaded = this.fileUploaded.bind(this);
	    this.loadAnonymousUserList = this.loadAnonymousUserList.bind(this);
	    this.saveAnonymousUserListsToSever = this.saveAnonymousUserListsToSever.bind(this);
	    this.saveSecondaryAnonymousList = this.saveSecondaryAnonymousList.bind(this);
		this.savePrimaryAnonymousList = this.savePrimaryAnonymousList.bind(this);
		this.addItemsToSearchAble = this.addItemsToSearchAble.bind(this);
		this.getDiscoveryList = this.getDiscoveryList.bind(this);

		this.anonListKey = "anonymousList";
		this.state = {
			author: (UserState()) ? UserState().username : "",
			idCounter: 0,
			dataLoading: true,
			maxListSize: 100,
			rankListId: props.rankId,
			rankDetails: {
				name: ''
			},
			rankItems: [
			],
			typingTimeout: 0,
			anonymousUser: false,
			url: '',
			redirect: '',
			showUploader: false,
			showRankItemSelector: false,
			parentId: "",
			searchableItems: [],
		}
	}


	closeModal() {
		this.setState({showUploader: false, "showRankItemSelector": false});
	}

	uploadImage(item) {
		this.setState({showUploader: true});
	}


	fileUploaded(data) {
		var newRankDetails = {...this.state.rankDetails};
		newRankDetails.imageUrl = data.imageUrl;
		this.setState({rankDetails:newRankDetails}, () => {
			this.saveList(this.state);
		});
	}

	delaySave() {
		var self = this;
		return setTimeout(function () {
       		if(!self.state.dataLoading) {
				self.saveList.call(self, self.state);
			}
     	}, 2000)
	}

	handleNameChange(newName) {
	    if (this.typingTimeout) {
	       clearTimeout(this.typingTimeout);
	    }

	    var newRankDetails = {...this.state.rankDetails};
		newRankDetails.name = newName;
		this.setState({rankDetails: newRankDetails});
		this.typingTimeout = this.delaySave();
	}

	rankItemChanged(oldItem, newItem) {
		if (this.typingTimeout) {
	    	clearTimeout(this.typingTimeout);
	    }

		const itemIndex = this.state.rankItems.indexOf(oldItem);
		const newList = this.state.rankItems.slice();
		newList[itemIndex] = newItem;
		this.setState({
			rankItems: newList
		});

		this.typingTimeout = this.delaySave();
	}

	handleItemRankChange(items) {
		items.forEach((item, index) => {
			item.rank = index + 1;
		});
		
		this.setState({rankItems: items} , this.saveList.bind(this, this.state));
	}

	saveList(data) {
		if(this.state.anonymousUser) {
			localStorage.setItem(this.anonListKey, JSON.stringify(this.state));
			return;
		}
		
	    return saveList(this.state, this.state.rankListId)
	    	// .then(console.log);
	}

	addRankItem(newItem) {
		//Show Selector.
		this.setState({"showRankItemSelector": true});
		
	}

	createNewRankItem(thisItem) {
		if(thisItem.id) {
			thisItem.rank = this.state.rankItems.length + 1
			var newList = this.state.rankItems.concat([thisItem]);
		} else {
			var newList = this.state.rankItems.concat([{
				author: (UserState()) ? UserState().username : "",
				id: uuidv4(),
				rank: this.state.rankItems.length + 1,
				name: "Click to edit.",
				author: (UserState()) ? UserState().username : ""
			}]);
		}
		this.state.idCounter++;
		this.setState({rankItems: newList}, this.delaySave);
	}

	removeRankItem(theItem) {
		var rankItemsCopy = [...this.state.rankItems];
		var itemIndex = rankItemsCopy.indexOf(theItem);
		rankItemsCopy.splice(itemIndex, 1);
		rankItemsCopy.forEach((x, index) => {
			x.rank = index + 1;
		})
		this.setState({rankItems: rankItemsCopy}, this.saveList.bind(this, this.state));
	}

	loadAnonymousUserList() {
		this.setState({anonymousUser: true});
		var data = AnonList();
		if(data) {
			data.rankItems.sort((a,b) => (a.rank > b.rank) ? 1: -1);
			this.setState(data);
		}
		this.setState({dataLoading: false});
		return;
	}

	//We have a user and anonymous
	saveAnonymousUserListsToSever(localList) {
		if(Anon2List()) {
			this.saveSecondaryAnonymousList();
		}

		this.savePrimaryAnonymousList(localList);
	}

	saveSecondaryAnonymousList() {
		createNewListWithData(Anon2List())
					.then(() => {
						ClearAnon2List();
					});
	}

	savePrimaryAnonymousList(localList) {
		createNewListWithData(localList)
			.then((res) => {
				this.setState(localList);
				ClearAnonList();
				this.setState({
					url: '/edit/' + res.id,
					redirect: true
				});
			});
	}

	getDiscoveryList() {
		getMyListById(UserState().discovery_list)
			.then(data => data.list_body.rankItems)
			.then(this.addItemsToSearchAble);
	}

	addItemsToSearchAble(newItems) {
		if(newItems) {
			newItems = newItems.filter(item => !this.state.rankItems.find(rankItem => rankItem.id == item.id));
			var items = this.state.searchableItems.concat(newItems);
			this.setState({"searchableItems": items});
		}
	}

	componentDidMount() {
		//Do We have a user?
		if(!UserState()) {
			this.loadAnonymousUserList();
			return;
		}
		this.setState({anonymousUser: false});

		//We have a user.  This user have a list in local?
		var localList = GetLocalSavedList();
		if(localList) {
			this.saveAnonymousUserListsToSever(localList);
			return;
		}

		if(this.state.rankListId == 'new') {
			createNewList()
				.then((res) => {
					this.setState({rankListId: res.id}, () => {
						this.state.dataLoading = false;
					});
				})

			return;
		}

		this.getDiscoveryList();

		getMyListById(this.state.rankListId)
			.then((data) => {
				data.list_body.anonymousUser = false;
				var dbData = {};

				//If the listBody Missing Detalis
				if(!data.list_body.rankDetails) {
					data.list_body.rankDetails = {name: "New List"}
				}

				//If the list_body is missing rankItems set the empty array.
				if(!data.list_body.rankItems) {
					dbData.rankItems = [];
				} else {
					dbData.rankItems = data.list_body.rankItems
				}
				dbData.rankItems.sort((a,b) => (a.rank > b.rank) ? 1: -1);
				dbData.rankDetails = data.list_body.rankDetails;
				dbData.parentId = data.list_body.parentId;
				dbData.rankItems.forEach(x => {
					if(x.author == "") {
						x.author = UserState().username;
					}
				});
				if(data.list_body.author) {
					data.author = data.list_body.author;
				} else {
					data.author = UserState().username;
				}

				var masterId = (dbData.parentId) ? dbData.parentId : this.state.rankListId;
				getStackSquash(masterId)
					.then(data => data.list_body.rankItems)
					.then(this.addItemsToSearchAble);

				this.setState(dbData, () => {
					this.state.dataLoading = false;
				});
				this.setState({rankListId: data.id});
			})
			.catch(console.log)
	}
	
	unpublish() {
		unpublishRankList(this.state.rankListId);
	}

	render() {
		return (
			<div className="rankList">

				<ImageUploader 
		        	show={this.state.showUploader}
		            closeModal={this.closeModal}
		            fileUploaded={this.fileUploaded}
		         />
		        <RankItemSelector 
		        	show={this.state.showRankItemSelector}
		            closeModal={this.closeModal}
		            searchableItems={this.state.searchableItems}
		            createNewRankItem={this.createNewRankItem.bind(this)}
		        />
				<RankViewRouting 
		          url={this.state.url}
		          redirect={this.state.redirect}
		        /> 
				
				{!this.state.parentId && 
					<div>
						<RankListActions 
							anonymousUser={this.state.anonymousUser}
				          	rankListId={this.state.rankListId}
						/>
						<button 
							className="btn-primary rankListPreviewAction"
			            	onClick={this.uploadImage}
			            >
			            Upload Image
			            </button>
			            <button 
							className="btn-warning rankListPreviewAction"
			            	onClick={this.unpublish}
			            >
			            Unpublish
			            </button>

						<EditRankListDetails 
							handleNameChange={this.handleNameChange.bind(this)}
							rankDetails={this.state.rankDetails} 
						/>
					</div>
				}
				{this.state.parentId &&
					<div>
						<div className="votedList">
							<div className="alert alert-primary">This is a cloned list!</div>
						</div>
						<h1> 
							{this.state.rankDetails.name} 
						</h1>
					</div>
				}
				{this.state.rankItems.length > 0 &&
				
					<EditRankItems 
						handleItemRankChange={this.handleItemRankChange.bind(this)}
						rankItems={this.state.rankItems}
						removeRankItem={this.removeRankItem.bind(this)}
						rankItemChanged={this.rankItemChanged.bind(this)}
					/>
				}
				<div>{this.state.rankItems.length} of {this.state.maxListSize}</div>
				{this.state.rankItems.length < this.state.maxListSize &&
					<AddRankItemButton 
						addRankItem={this.addRankItem.bind(this)}
					/>
				}
			</div>
		)
	}
}

export default RankEditView;