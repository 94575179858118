import React from 'react';
import {Redirect} from 'react-router-dom';
import RankViewAPI from "../RankViewApi";
import {
  Link,
  useHistory
} from "react-router-dom";
import "./User.css";


class RankViewRouting extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.redirect === true) {
      return <Redirect push to={this.props.url}/>
    }
    return (<div></div>);
  }
}

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.onFieldChange = this.onFieldChange;
    this.Login = this.Login.bind(this);
    this.state = {
      username: '',
      password: '',
      toUserLogin: false,
      userPassWrong: false,
      errorOccured: false,
      userId: 0,
      redirect: false,
      url: props.redirect,
      requestSent: false
    }
  }

  onFieldChange(property, e) {
    let loginObject = {...this.state};
    loginObject[property] = e.target.value;
    this.setState(loginObject);
  }

  Login() {
    const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username: this.state.username,
            password: this.state.password
          })
      };


      this.setState({userPassWrong: false, errorOccured: false, requestSent: true});

      RankViewAPI('auth', requestOptions, false)
        .then(res => res.json())
        .then((data) => {
            console.log(data);
            if(!data || !data.username) {
              Promise.reject("User Data is not valid");
            }
            localStorage.setItem("User", JSON.stringify(data));
            this.props.loggedInCallback(data,
              () => {
                var url = this.state.url || ('/userHome/' + data.username);
                this.setState({
                  userId: data.username, 
                  toUserLogin: true,
                  url: url,
                  redirect: true
                });
            });
          })
        .catch((err) => {
          this.setState({requestSent: false});
          if(err.status == 401) {
            this.setState({userPassWrong: true});
          } else {
            this.setState({errorOccured: true});
          }
        });
  }

  render() {
    // if (this.state.toUserLogin === true) {
    //   return <Redirect push to={'/userHome/' + this.state.userId} />
    // }

    return (
      <div className="container">
        <RankViewRouting 
          url={this.state.url}
          redirect={this.state.redirect}
        />
        <div className="card col-sm-12">
          <h1 className="d-flex justify-content-center"> 
            Login
          </h1>
          <div className="card-body">
              <div className="input-group form-group">
                <label>Username*</label>
                <input onChange={this.onFieldChange.bind(this, 'username')} value={this.state.username} />
              </div>

              <div className="input-group form-group">
                <label>Password*</label>
                <input type="password" onChange={this.onFieldChange.bind(this, 'password')} value={this.state.password} />
              </div>
              
              <div className="input-group form-group justify-content-center ">
                {!this.state.requestSent &&
                  <button className="btn btn-success btn-lg" onClick={this.Login} >Submit</button>
                }
                {this.state.requestSent && 
                  <div className="alert alert-success">Request Sent</div>
                }
              </div>
              {this.state.userPassWrong && <div className="alert alert-danger">User Password incorrect</div>}
              {this.state.errorOccured && <div className="alert alert-danger">Error occured</div>}
              <div>
                <div className="text-muted d-flex justify-content-center links">
                  Don't have an account?&nbsp; 
                    <Link to="/signup">
                      Sign Up
                    </Link>
                </div>
                <div className="d-flex justify-content-center">
                  <a href="#">Forgot your password?</a>
                </div>
              </div>
              
          </div>
        </div>
      </div>
    )
  }
}

export default Login;