import React from 'react';

class RankItemAddress extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div>
          {this.props.item.address}<br />
          {this.props.item.city && 
            this.props.item.city + ", " + this.props.item.state} {this.props.item.zip}
        </div>
    )
  }
}

export default RankItemAddress;